import GlobalData from './GlobalData';
import Constants from './Constants';

var enterprise = GlobalData.enterprise_data.Supermeal_dev;

const Config = {
    Setting : {
        baseUrl : enterprise.baseUrl,
        apiURL: enterprise.coreApiURL,
        clientApi: enterprise.clientApi,
        baseImageURL: enterprise.baseImageUrl,
        currencySymbol: enterprise.currency,
        timeZone: enterprise.timezone,
        termsAndConditionLink: enterprise.termsAndConditionLink,
        isUk: enterprise.isUk,
        currency: enterprise.currency,
        csvSeperator: enterprise.csvSeperator,
        decimalPlaces : enterprise.decimalPlaces,
        distanceUnit: enterprise.distanceUnit,
        dateFormat: enterprise.dateFormat,
        heartbeatInterval : 10000, //1000 is one sec,
        app : "SuperCenter",
        countryCode : enterprise.countryCode,
        zoneLimit : enterprise.zoneLimit,
        goOfflineHours : "2,4,6",
        goOfflineDays : "0,1,2,3,4,5,6,7",
        googleApi: enterprise.Google_Api_Key,
        DefaultLocation: enterprise.Google_Lat_Long,
        SupportEmail: enterprise.SupportEmail,
        CategoryImage_W_H: enterprise.Category_Image_Width_Height,
        ProductImage_W_H: enterprise.Product_Image_Width_Height,
        bankkDetails: enterprise.Enterprise_Bank_Details,
        itemFilters: enterprise.Item_Filters,
        SessionExpiry: enterprise.Session_Expire_In_Hours,
        preOrderAcceptDuration: enterprise.PreOrderAcceptDuration

      },


      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'App': "SuperCenter",
        'Authorization': "Bearer " + localStorage.getItem(Constants.Session.JWT_TOKEN),
        'AuthenticationTicket': localStorage.getItem(Constants.Session.AUTHENTICATION_TICKET) === "null" ? "" : localStorage.getItem(Constants.Session.AUTHENTICATION_TICKET),
        'ImpersonatorID': localStorage.getItem(Constants.Session.IMPERSONATORID) === "null" ? "" : localStorage.getItem(Constants.Session.IMPERSONATORID)
    }
} 
  
  export default Config