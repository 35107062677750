import Config from './Config';
var baseURL = Config.Setting.apiURL;
var baseImageURL = Config.Setting.baseImageURL;
var wcfUrl = Config.Setting.baseUrl
 
const UrlConstant= {
    ThumbImageURL: baseImageURL + "/images/",
    LargeImageURL: baseImageURL + "/images/",
    Heartbeat : baseURL + "/Auth/Heartbeat",
    Auth : baseURL + "/Auth",
    Login: baseURL + "/Login",
    SignOut: baseURL + "/Signout",
    AccountStatement : baseURL + "/Statement/",
    EnterpriseOrders : baseURL + "/Order/Enterprise/",
    EnterpriseInvoices : baseURL + "/EnterpriseInvoice/",
    EnterpriseCategories : baseURL + "/Category/",
    Category : baseURL + "/Category/",
    EnterpriseCategoryProduct : baseURL + "/Product/",
    Product : baseURL + "/Product/",
    VarietyLabel : baseURL + "/VarietyLabel/",
    EnterpriseCategoryProductOption : baseURL + "/ProductOption/",
    EnterpriseSetting : baseURL + "/EnterpriseSetting/",
    EnterpriseService : baseURL + "/EnterpriseService/",
    EnterpriseAddonGroup : baseURL + "/AddonGroup/",
    EnterpriseTopping : baseURL + "/Topping/",
    EnterpriseMenu : baseURL + "/EnterpriseMenu/",
    EnterpriseUsers : baseURL + "/EnterpriseUser/",
    Area : baseURL + "/Area/",
    Tag : baseURL +"/Tag",
    GlobalConfiguration: baseURL + '/GlobalConfiguration/',
    DeliveryArea: baseURL + '/DeliveryArea/',
    City: baseURL + '/City/',
    EnterpriseAddress: baseURL + '/EnterpriseAddress/',
    DeliveryZone: baseURL + '/DeliveryZone/',
    Town: baseURL + '/Town/',
    Permission: baseURL + '/Permission/',
    Enterprise: baseURL + '/Enterprise/',
    Commission: baseURL + '/Commission/',
    User: baseURL + '/User/',
    Account: baseURL + '/Account/',
    EnterpriseQuickSetting : baseURL + "/EnterpriseSetting/QuickSetting",
    EnterpriseProfileHealth : baseURL + "/EnterpriseSetting/GetProfileHealth/",
    EnterpriseAgent: baseURL + '/EnterpriseAgent/',
    ScheduleEnterpriseQuickSetting : baseURL + "/EnterpriseSetting/ScheduleQuickSetting",
    PhotoDictionary: baseURL + '/PhotoDictionary/',
    EnterpriseRating: baseURL + '/Rating/',
    EnterpriseOrderRatingDetails: baseURL +  "/OrderDetail",
    Campaign: baseURL + '/Campaign/',
    EnterpriseVoucher: baseURL + '/Voucher/',
    EnterpriseVoucherBatch: baseURL + '/VoucherBatch/',
    FoodTypeCuisine: baseURL + '/FoodTypeDictionary/',
    Brand: baseURL + '/ProductOption/',
    Orders: wcfUrl + '/wcf/OrderManagement.svc/',
    UpdateOrder: baseURL,
    Device : baseURL + '/Device',
    Notification: baseURL + '/Notification/',
    MoreImagesUrl: baseURL + '/ProductOption/MoreImages',
    MoreFilesUrl:"", //baseURL + '/ProductOption/MoreImages',
    HTMLeditorUrl: baseURL + '/ProductOption/SaveItemInfoDetail/',
    COUNTRY:  baseURL + "/Country",
    ACTIVE_COUNTRIES: baseURL +  "/Country/Active",
    UpdateConfig: baseURL + "/Country/UpdateConfiguration",
    GetTheme: baseURL + "/EnterpriseSetting/Theme",
    UpdateTheme: baseURL + "/EnterpriseSetting/UpdateThemeJson",
    UpdateCustomCss: baseURL + "/EnterpriseSetting/UpdateCustomCss",
    UpdateHomeSettings: baseURL + "/EnterpriseSetting/SaveHomeSettings",
    SaveNavigation: baseURL + "/EnterpriseSetting/SaveNavigation",
    AreaPolygon: baseURL + "/AreaPolygon/",
    EnterpriseCMS: baseURL + '/EnterpriseCMS/',
    ProductVariety: baseURL + '/ProductVariety/',
    ProductVarietyStock: baseURL + '/ProductVarietyStock/',
    Sale: baseURL + '/Sale/',
    SocialMediaPlatform: baseURL ,
    EnterpriseCustomField: baseURL + '/EnterpriseCustomField/',
    EnterpriseProductJson: baseURL + '/EnterpriseProductJson/',
    BusinessType: baseURL + '/BusinessType/',
    OrderDetail: baseURL + '/OrderDetail/',
    SubscriptionFeature: baseURL + '/feature/subscription',
    AddSubscription: baseURL + '/subscription',
    SubscriptionPlan: baseURL + '/subscription',
    ImportDataMapping: baseURL + '/ImportDataMapping/'
}

export default UrlConstant;