import React, { Component } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.scss';
import Loader from 'react-loader-spinner';
import { IoAddOutline } from "react-icons/io5";
const   loading = () =>   <div className="page-laoder">
<div> 
   <Loader 
type="Oval"
color="#ed0000"
height={50}	
width={50}/>  
<div className="loading-label">Loading.....</div>
</div>
</div> ;
// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
  
});

// Pages
const Logout = Loadable({
  loader: () => import('./views/Pages/Logout'),
  loading
});
const Verification = Loadable({
  loader: () => import('./views/Pages/Verification'),
  loading
});

const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});
const Print = Loadable({
  loader: () => import('./views/Orders/Print'),
  loading
});

const ForgotPassword = Loadable({
  loader: () => import('./views/ForgotPassword/ForgotPassword'),
  loading
});


const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

const OrderDetail = Loadable({
  loader: () => import('./views/OrderDetail'),
  loading
});


class App extends Component {
  componentDidMount() {
    document.body.classList.add('remove-hidden');
    document.body.classList.add('menu-slide-new');
   }
   componentWillUnmount(){
    document.body.classList.remove('menu-slide-new');
    document.body.classList.remove('remove-hidden');
   }

  render() {
    return (
      <BrowserRouter basename='/'>
          <Switch>
            <Route path="/login/app/:mid/:authid/:isapp" name="Login Page" component={Login} />
            <Route path= '/order-receipt/:enterpriseId/:token/:orderId' name= 'Order Detail' component={OrderDetail} />
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/verification" name="Login Page" component={Verification} />
            <Route exact path="/print/:token" name="Print" component={Print} />
            <Route exact path="/logout" name="Logout Page" component={Logout} />
            <Route exact path="/forgot-password" name="Forgot Password" component={ForgotPassword} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
